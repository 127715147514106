<script setup lang="ts">
defineProps<{
  error: Object
}>()

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <AppError v-bind="error" @action="handleError()" />
</template>
