export default defineNuxtRouteMiddleware(async (to) => {
  const { $auth0, $auth0Ready, $datadog } = useNuxtApp()

  if (to.meta.public) {
    return
  }

  if (process.client) {
    await $auth0Ready()
    const { user } = $auth0()

    if (user.value) {
      const { email, name, sub } = user.value

      const roles = user.value?.['https://auth.rialtic.io/roles']

      $datadog.setUser({
        id: sub,
        name,
        email,
        roles: roles.join(', '),
        email_domain: email?.split('@')[1],
      })
    }
  }
})
